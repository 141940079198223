<template>
    <div
        class="mx-12 lg:60 xl:mx-48 columns-1 sm:columns-2 md:columns-3 lg:columns-4 overflow-visible gap-4 space-y-4 p-4"
    >
        <div
            v-for="image in data?.images"
            :key="image.directus_files_id"
            class="overflow-hidden"
        >
            <img
                class="image"
                loading="lazy"
                :src="
                    config.public.directus.url +
                    '/assets/' +
                    image.directus_files_id +
                    '?width=400'
                "
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { readSingleton } from '@directus/sdk'

const { $directus } = useNuxtApp()
const config = useRuntimeConfig()

const { data } = await useAsyncData('gallery', () => {
    return $directus.request(
        readSingleton('gallery', {
            fields: [
                {
                    images: ['directus_files_id'],
                },
            ],
        })
    )
})

console.log(data)
</script>
